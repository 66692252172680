import React from 'react';

export const constants = {
  typeNames: {
    "m5d.large": "Light Speed",
    "m5ad.large": "Light Speed",
    "c5d.xlarge": "Ridiculous Speed",
    "c5ad.xlarge": "Ridiculous Speed",
    "c5d.2xlarge": "Ludicrous Speed",
    "c5ad.2xlarge": "Ludicrous Speed"
  },
  typePrices: {
    "m5d.large": 0.4,
    "m5ad.large": 0.4,
    "c5d.xlarge": 0.7,
    "c5ad.xlarge": 0.7,
    "c5d.2xlarge": 1.0,
    "c5ad.2xlarge": 1.0
  }
};

export function sleep(delay, passthrough) {
  let p = new Promise(function(resolve) {
    window.setTimeout(resolve, delay);
  });
  if (passthrough) {
    return p.then(() => {return passthrough;});
  } else {
    return p;
  }
}

export function runAt(time, f) {
  let now = new Date();
  let millis = time - now;
  if (millis < 0) { // time has passed, run now
    f();
  } else {
    window.setTimeout(f, millis);
  }
}

// Flattens all child elements into a single list
function flatten(children, flat = []) {
  flat = [ ...flat, ...React.Children.toArray(children) ];
  if (children.props && children.props.children) {
    return flatten(children.props.children, flat);
  }
  return flat;
}

// Strips all circular references and internal fields
function simplifyChildren(children) {
  const flat = flatten(children);
  return flat.map(
    ({
      key,
      ref,
      type,
      props: {
        children,
        ...props
      }
    }) => ({
      key, ref, type, props
    })
  );
}

export function childrenString(children) {
  return JSON.stringify(simplifyChildren(children));
}

export function getContentSize(id) {
  return {
    x: document.getElementById(id || 'page-content-wrapper').offsetWidth,
    y: document.getElementById(id || 'page-content-wrapper').offsetHeight
  };
}

export function getEmSize(id) {
  return parseFloat(window.getComputedStyle(document.getElementById(id || 'page-content-wrapper')).fontSize);
}

export function zeroPad(num, places) {return String(num).padStart(places, '0');}
