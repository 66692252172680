import React from 'react';
import Children from 'react-children-utilities'; // https://github.com/fernandopasik/react-children-utilities
import Icon from '@mdi/react';
import {
  mdiDelete,
  mdiMenu,
  mdiStop
} from '@mdi/js';

const iconTable = {
  'trash': mdiDelete,
  'hamburger': mdiMenu,
  'stop': mdiStop,
};

export default function mdi(props) {
  return (
    <Icon path={iconTable[Children.onlyText(props.children)]} color={props.color || "white"} size={props.size || "1em"} />
  );
}
