import React from 'react';
import Form from 'react-bootstrap/Form';

export default function Checkbox(props) {
  return (
    <Form.Group controlId={props.id} className={"col-12 " + (props.col ? "col-lg-" + props.col : "col-lg-6")}>
      <Form.Check id={props.id} required={props.required} type="checkbox" checked={props.obj[props.id] || ""} onChange={props.handler} label={props.children} aria-describedby={props.help ? props.id + "-help" : undefined} />
      { props.help ? <Form.Text id={props.id + "-help"} muted>{props.help}</Form.Text> : undefined }
      { props.invalid ? <Form.Control.Feedback type="invalid">{props.invalid}</Form.Control.Feedback> : undefined }
    </Form.Group>
  );
}
