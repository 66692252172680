import React, {useState} from 'react';

export default function LoadingButton(props) {
  const [loading, setLoading] = useState(false);

  const disabled = props.disabled || loading ? true : undefined;
  const css = "btn " + (props.className || "btn-primary") + (disabled ? " disabled" : "");
  const style = {
    width: (props.width || (props.children.length + 'em'))
  };
  
  return (
    <button type={props.type || "button"} className={css} style={style} onClick={ loading ? e => {e.preventDefault();} : event => {
      props.onClick(event).then(() => {setLoading(false);});
      setLoading(true);
    }} disabled={disabled}>
      {loading ? (<img src="/img/tail-spin.svg" alt="Loading..." style={{height: '1.25em'}} />) : props.children}
    </button>
  );
}
