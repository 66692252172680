import React from 'react';
import { Panel } from 'parts/Parts.jsx';
import Console from './Console.jsx';
import { sleep } from 'api/util.js';
import api from 'api/API.js';
import Swal from 'sweetalert2';

class Instance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      log: "",
      instance: {},
      noServer: false
    };

    this.poll = false;

    // js bindings
    this.connect = this.connect.bind(this);
    this.sendText = this.sendText.bind(this);
    this.startServer = this.startServer.bind(this);
    this.terminateServer = this.terminateServer.bind(this);
    this.loopUntilReady = this.loopUntilReady.bind(this);
    this.override = this.override.bind(this);
  }

  sendText(val) {
    console.log(val);
    this.websocket.sendCommand(val);
  };
  
  connect(dns) {
    this.websocket = api.instance.getWebsocket(dns, (message) => { // log receiver
      this.setState({log: this.state.log + "\n" + message.body});
    }, () => { // other 'on connect' stuff
      this.websocket.setTerminateCallback(() => { // on terminate
        this.websocket.disconnect();
        Swal.fire({title: 'Server has terminated', text: 'Please wait.', icon: 'success'});
        this.setState({noServer: true});
        api.user.getUser();
      });
    });

    this.websocket.connect();
  }

  terminateServer() {
    this.websocket.terminate();
    Swal.fire({title: 'Server is now terminating', text: 'Please wait.', icon: 'success'});
  }

  startServer() {
    this.websocket.start();
  }

  loopUntilReady(ret) {
    if (!this.poll) {
      console.log("Cancelling ready loop...");
      return null;
    }

    if (ret && ret.dns) {
      return ret;
    } else {
      // wait 1 second, then get the instance again
      return sleep(2000).then(() => {
        console.log("going around again!");
        return api.instance.getInstance(ret.id).then(this.loopUntilReady);
      });
    }
  }
  
  componentDidMount() {
    this.poll = true;

    // admin override
    if (this.props.match.params.id && api.user.hasPermission("SUPER_ADMIN")) {
      api.instance.getInstance(this.props.match.params.id).then(this.loopUntilReady).then(ret => {
        if (ret) {
          this.setState({instance: ret});
          this.connect(ret.dns);
        }
      });
      // regular use
    } else {
      if (api.user.getCachedUser().servers.length > 0) {
        api.instance.getInstance(api.user.getCachedUser().servers[0]).then(this.loopUntilReady).then(ret => {
          if (ret) {
            this.setState({instance: ret});
            this.connect(ret.dns);
          }
        });
      } else {
        this.setState({noServer: true});
        return;
      }
    }
  }

  componentWillUnmount() {
    this.poll = false;
    if (this.websocket) {
      this.websocket.disconnect();
    }
  }

  override(e) {
    this.poll = false;
    api.instance.getInstance(document.getElementById("override").value).then(sleep(1000)).then(ret => {
      if (ret) {
        console.log(ret);
        this.poll = true;
        this.setState({instance: ret, noServer: false});
        this.connect(ret.dns);
      } else {
        this.poll = false;
        this.setState({instance: ret, noServer: false});
      }
    });
  }
  

  render() {
    if (this.state.noServer) {
      return (
        <div className="row justify-content-around m-0 full-width">
          {api.user.hasPermission("SUPER_ADMIN") ?
           <Panel title="Super-admin connection override" className="ml-3">
             <input id="override" type="text" size="36" placeholder="65b90183-90a1-44a3-bc77-2705d771b513" /> <button className="btn btn-primary" onClick={this.override}>{"->"}</button>
           </Panel>
           : null}
          <div>
            <p>Looks like you don't have a server started ¯\_(ツ)_/¯</p> <br />
            <p>Maybe you should go start one?</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row justify-content-around m-0 ">
          <Console title={this.state.instance.dns} data={this.state.log} sendText={this.sendText}/>
          <Panel className="mx-0 justify-content-center justify-content-between">
            <button className="btn btn-secondary mr-auto" onClick={this.startServer}>Start</button>
            <button className="btn btn-danger" onClick={this.terminateServer}>Terminate</button>
          </Panel>
          {api.user.hasPermission("SUPER_ADMIN") ?
           <Panel title="Super-admin connection override" className="ml-3">
             <input id="override" type="text" size="36" defaultValue={this.state.instance.id} /> <button className="btn btn-primary" onClick={this.override}>{"->"}</button>
           </Panel>
           : null}
        </div>
      );
    }
  }
}

export default Instance;
