import api from './API.js';
import {Client} from '@stomp/stompjs';

class Instance {
  getInstance(id) {
    return window.fetch(
      id ? api.getApiDomain() + '/instance/' + id : api.getApiDomain() + '/instance',
      api.get()).then(resp => {return resp.json();}).then(dto => {
        if (dto.success) {
          return dto.data;
        } else {
          console.log(dto);
          return null;
        }
      });
  }

  startInstance(configId) {
    return window.fetch(api.getApiDomain() + '/instance/' + configId, api.post()).then(resp => {return resp.json();}).then(dto => {
      if (dto.success) {
        api.user.getUser();
        return dto.data;
      } else {
        console.log(dto);
        return dto;
      }
    });
  }

  // websockets

  getWebsocket(dns, logListener, onConnect) {
    return new Websocket("wss://" + dns + ":8443/server", logListener, onConnect);
  }

  // legacy server handling

  getLog(dns) {
    return window.fetch('https://' + dns + ':42069/log', api.get()).then(resp => {return resp.json();});
  }

  sendCommand(dns, command) {
    return window.fetch('https://' + dns + ':42069/command?command=' + window.encodeURI(command), api.post()).then(resp => {
      return resp.json();
    }).then(ret => {
      return ret.console;
    });
  }

  terminate(dns) {
    return window.fetch('https://' + dns + ':42069/terminate', api.post()).then(resp => {
      return resp.json();
    }).then(ret => {
      return ret.console;
    });
  }

  start(dns) {
    return window.fetch('https://' + dns + ':42069/start', api.post()).then(resp => {
      return resp.json();
    });
  }
}

class Websocket {
  constructor(url, logListener, onConnect) {
    // set up some local values
    this.ready = false;
    
    this.client = new Client({
      brokerURL: url,
      connectHeaders: {
        auth: api.__keys.id_token
      },
      reconnectDelay: 3000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    this.client.onConnect = () => {
      console.log("connected to websocket @ " + url);
      this.ready = true;
      this.client.subscribe('/topic/log', logListener);
      this.client.publish({
        destination: '/app/introduction'
      });
      // execute callback if available
      if (onConnect) {
        onConnect();
      }
    };

    this.client.onWebSocketClose = (closeEvent) => {
      console.log("lost connection");
      console.log(closeEvent);
      this.ready = false;
    };

    this.client.onStompError = (err) => {
      console.log("ERROR");
      console.log(err);
    };

    this.client.onError = (err) => {
      console.log('Websocket error: ', err);
    };
  }

  connect() {
    if (this.ready) return;
    this.client.activate();
  }

  disconnect() {
    this.client.deactivate();
  }

  sendCommand(command) {
    if (!this.ready) return;
    this.client.publish({
      destination: '/app/command',
      body: command
    });
  }

  terminate() {
    if (!this.ready) return;
    this.client.publish({
      destination: '/app/terminate'
    });
  }

  setTerminateCallback(callback) {
    this.client.subscribe('/topic/terminate', callback);
  }

  start() {
    if (!this.ready) return;
    this.client.publish({
      destination: '/app/start'
    });
  }

  isReady() {
    return this.ready;
  }
}

export default Instance;
