import React from 'react';
import api from 'api/API.js';
import {NavLink/*, Link*/} from 'react-router-dom';

function SidebarLink(props) {
  return (
    <NavLink to={props.to} exact={props.exact} className="list-group-item list-group-item-action" activeClassName="inset-shadow">{props.children}</NavLink>
  );
}

function Sidebar() {
  console.log(api.user && api.user.hasPermission("SUPER_ADMIN"));
  return (
    <div className="border-right" id="sidebar-wrapper">
      <div className="sidebar-heading">
        <img style={{width: '200px'}} src="/img/FerroFluid_full_logo.png" alt="FerroFluid logo"/>
      </div>
      <div className="list-group list-group-flush">
        <SidebarLink exact to="/">Home</SidebarLink>
        <SidebarLink to="/configs">Games</SidebarLink>
        <SidebarLink to="/server">Server</SidebarLink>
        {api.user && api.user.hasPermission("SUPER_ADMIN") ? <SidebarLink to="/admin">Admin Panel</SidebarLink> : null}
      </div>
    </div>
  );
}

export default Sidebar;
