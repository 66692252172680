import {loadStripe} from '@stripe/stripe-js';
// attached classes
import aUser from './User.js';
import aInstance from './Instance.js';
import aConfig from './Config.js';
import aAdmin from './Admin.js';

class API {
  set keys(val) {
    if (val) {
      // add api properties here:
      this.user = new aUser(val.id_token);
      this.instance = new aInstance();
      this.config = new aConfig();
      this.admin = new aAdmin();
      this.__keys = val;
    }
  }

  get keys() {
    return this.__keys;
  }

  constructor() {
    this.loaded = false;
    const stripeKey = (window.location.host === "console.dev.ferrofluidgames.com" || window.location.host === "localhost:3000") ?
          "pk_test_51HoFdKGT9e9zfLCKOJcu83CUxfEd6w5zmDlR2Rn84I5dLn2UuTVTuUeTALPP4Q1HBJZcGYa1XEXOVo6ZJj8YNhJc00SdKnkXk7" :
          "pk_live_51HoFdKGT9e9zfLCKiij8yonlo7x4gGdeoKDoF7bXA9GkYPdv1SLOkKraYBF7EilIWFagHlVHfFjTRleKZSU5XDDM00fKT1bobb"; // TODO: add live key
    
    this.stripePromise = loadStripe(stripeKey);
  }

  waitUntilLoaded() {
    return new Promise(function (resolve, reject) {
      var waitForLoad = () => {
	// eslint-disable-next-line no-use-before-define
	if (api.user && api.user.getCachedUser()) return resolve();
	window.setTimeout(waitForLoad, 100);
      }
      waitForLoad(resolve);
    }).then(() => {
      console.log("API loaded");
      this.loaded = true;
    });
  }

  get() {
    return {
      method: 'GET',
      headers: {
	'Accept': 'application/json',
	'Authorization': 'Bearer ' + this.__keys.id_token
      },
      cache: "no-cache"
    };
  }

  post(qBody) {
    return {
      body: JSON.stringify(qBody || null),
      method: 'POST',
      headers: {
	'Content-Type': 'application/json',
	'Accept': 'application/json',
	'Authorization': 'Bearer ' + this.__keys.id_token
      }
    };
  }

  put(qBody) {
    return {
      body: JSON.stringify(qBody || null),
      method: 'PUT',
      headers: {
	'Content-Type': 'application/json',
	'Accept': 'application/json',
	'Authorization': 'Bearer ' + this.__keys.id_token
      }
    };
  }

  delete() {
    return {
      method: 'DELETE',
      headers: {
	'Accept': 'application/json',
	'Authorization': 'Bearer ' + this.__keys.id_token
      }
    };
  }

  getApiDomain() {
    return (window.location.host === "console.dev.ferrofluidgames.com" || window.location.host === "localhost:3000")
      ? "https://api.dev.ferrofluidgames.com"
      : "https://api.ferrofluidgames.com";
  }
}

var api = new API();

if (window.location.hostname === "localhost") {
  window.ffapi = api;
}

export default api;
export var environment = null;
