import api from './API.js';

export default class Admin {
  getAllInstances(id) {    
    return window.fetch(
      api.getApiDomain() + '/admin/all-instances', api.get())
      .then(resp => {return resp.json();}).then(dto => {
        if (dto.success) {
          return dto.data;
        } else {
          return null;
        }
      });
  }

  getAllUsers(id) {
    return window.fetch(
      api.getApiDomain() + '/admin/all-users', api.get())
      .then(resp => {return resp.json();}).then(dto => {
        if (dto.success) {
          return dto.data;
        } else {
          return null;
        }
      });
  }

  getBilling(userId) {
    return window.fetch(
      api.getApiDomain() + 'instance/user/month/' + userId, api.get())
      .then(resp => {return resp.json();}).then(dto => {
        if (dto.success) {
          return dto.data;
        } else {
          return null;
        }
      });
  }
}

