import React from 'react';

const lMap = {
  'dots-dark': 'three-dots.svg',
  'spinner': 'ff_spinner.webp'
};

function Loader(props) {
  return (
    <img src={"/img/" + lMap[(props.type || 'dots-dark')]} style={{height: (props.height || '100%')}} alt="Loading..."/>
  );
}

export default Loader;
