import React from 'react';
import { Switch, Route } from "react-router-dom";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={require('views/Root.jsx').default}/>
      <Route exact path="/configs" component={require('views/configs/Configs.jsx').default}/>
      <Route exact path="/server" component={require('views/instances/Instance.jsx').default}/>
      <Route path="/server/:id" component={require('views/instances/Instance.jsx').default}/>
      <Route exact path="/user" component={require('views/user/EditUser.jsx').default}/>
      <Route exact path="/admin" component={require('views/admin/Admin.jsx').default}/>
    </Switch>
  );
}

export default Routes;
