import React, { useState } from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import api from 'api/API.js';
import './CardElement.css';
import Swal from 'sweetalert2';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function openCardModalFactory(setModalProps) {
  return function() {
    return new Promise(function(resolve, reject) {
      setModalProps({
        show: true,
        onHide: function (input) {
          setModalProps({show: false});
          reject(input);
          console.log('rejected promise');
        },
        onSuccess: function(input) {
          setModalProps({show: false});
          resolve(input);
          console.log('resolved promise');
        }
      });
    });
  };
}

export function useCardModal() {
  const [modalProps, setModalProps] = useState({
    show: false,
    onHide: undefined,
    onSuccess: undefined
  });
  return [openCardModalFactory(setModalProps), modalProps];
}

export default function CardModal(props) {
  const stripe = useStripe();
  const elements = useElements();

  async function submit(event) {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    try {
      const promo = document.getElementById("promo-code").value;
      let promoResponse = null;
      
      if (promo) {
        promoResponse = await api.user.addPromo(promo);
        if (promoResponse && promoResponse.message === "invalid promo code") {
          Swal.fire({title: 'Invalid promo code.', icon: 'error'});
          return;
        }
        if (promoResponse && promoResponse.message === "promo already used") {
          Swal.fire({title: 'Promo code already used.', icon: 'error'});
          return;
        }
      }
      
      const cardElement = elements.getElement(CardElement);

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.log(error);
        Swal.fire({title: 'Whoops!', text: 'There was an error submitting that card.\nPlease try again!', icon: 'error'});
        return;
      }

      console.log(paymentMethod);
      const response = await api.user.subscribeUser(api.user.getCachedUser().id, paymentMethod.id);
      console.log(response);

      if (promoResponse && promoResponse.data.applied) {
        await Swal.fire({title: '$' + (response.data.promo / 100).toFixed(2) + ' credit applied.', icon: 'success'});
      }
      
      api.user.getUser();
      
      props.onSuccess(response);
    } catch (err) {
      props.onHide(err);
    }
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Whoa there!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              <p>Looks like your payment information isn't up to date.</p>
              <p>We'll need to remedy that before you can continue.</p>
              <hr className="white"/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p>As we bill for what you use, we require that you enter your payment information before you can use our service.</p>
              <p>Don't worry, we won't charge anything until you've started a server.</p>
              <p>Please enter your card payment information:</p>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
              <p className="m-0"><small>Payment processing done by <a href="https://stripe.com/">Stripe</a>.</small></p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <p>Promo code:</p>
              <input type="text" placeholder="Enter a promo code..." id="promo-code" />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
        <Button variant="primary" className="my-3" onClick={submit}>Save card</Button>
      </Modal.Footer>
    </Modal>
  );
  
}
