import {useState, useRef, useEffect, useLayoutEffect} from 'react';
import {getContentSize, getEmSize} from './util';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useSize(parentId) {
  const [contentSize, setContentSize] = useState({x:0,y:0,em:1});
  useLayoutEffect(() => {
    function updateSize() {
      setContentSize({...getContentSize(parentId), em: getEmSize(parentId)});
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [parentId]);
  return contentSize;
}
