import React from 'react';
import { Panel } from 'parts/Parts.jsx';
import api from 'api/API.js';

// props.ret();
export default function InactiveUser(props) {
  console.log(api.user.getCachedUser());
  return (
    <div className="row justify-content-around">
      <Panel>
        <div className="p-3">
          <h2>Uh oh!</h2> <br />
          <p><b>FerroFluid is currently in closed beta.</b></p>
          <p>We're super excited to have you on board! We'll send an e-mail to {api.user.getCachedUser().email} as soon as we're ready!</p>
        </div>
      </Panel>
    </div>
  );
}
