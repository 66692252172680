import React from 'react';
import Form from 'react-bootstrap/Form';
import InputMask from "react-input-mask";

var mask = "+1 (999) 999-9999";

export default function PhoneNumber(props) {
  return (
    <Form.Group controlId={props.id} className={"col-12 " + (props.col ? "col-lg-" + props.col : "col-lg-6")}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control as={InputMask} mask={mask} minLength={17} required={props.required} type="tel"  placeholder={props.placeholder} value={props.obj[props.id] || ""} onChange={props.handler} aria-describedby={props.help ? props.id + "-help" : undefined} />
      { props.help ? <Form.Text id={props.id + "-help"} muted>{props.help}</Form.Text> : undefined }
      <Form.Control.Feedback type="invalid">Please enter a phone number</Form.Control.Feedback>
    </Form.Group>
  );
}

// <InputMask mask={mask} id={props.id} value={props.obj[props.id]} onChange={props.handler} type="tel" className="form-control"  />
