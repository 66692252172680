import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import './ff.scss';

let environment = (window.location.host === "console.dev.ferrofluidgames.com" || window.location.host === "localhost:3000") ? "development" : "production";

ReactDOM.render(
  <Auth0Provider
    domain={environment === "production" ? "ferrofluid.us.auth0.com" : "ferrofluid-dev.us.auth0.com"}
    clientId={environment === "production" ? "M9zZTZoazl3crJq2U3gDrmQbxrX8lRtM" : "n5V99geLUcRuDENtZmJQXSr4sqOPRM4B"}
    redirectUri={window.location.host === "localhost:3000" ? "http://localhost:3000/" :
                 (environment === "production" ? "https://console.ferrofluidgames.com/" : "https://console.dev.ferrofluidgames.com/")}
    audience={environment === "production" ? "https://api.ferrofluidgames.com/" : "https://api.dev.ferrofluidgames.com/"}
  >
    <App />
  </Auth0Provider>
  , document.getElementById('wrapper')
);
