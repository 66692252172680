import React, {useState} from 'react';
import { Loader, Modal } from 'parts/Parts.jsx';
import api from 'api/API.js';
import Swal from 'sweetalert2';
import CardModal, { useCardModal } from 'views/user/CardModal.jsx';
import Pagination from 'react-bootstrap/Pagination';

const ITEMS_PER_PAGE = 5;

const HR_TYPE = {
  'MINECRAFT': "Minecraft",
  'FACTORIO': "Factorio",
  'STEAM': "Steam game",
};

export default function CloneModal(props) {
  const [stockConfigs, setStockConfigs] = useState({});
  const [isProcessing, setProcessing] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [openCardModal, cardModalProps] = useCardModal();

  const [stockCount, setStockCount] = useState(0);
  
  async function onOpen() {
    // load configs when the new configuration modal opens to prevent constant scanning
    let configs = await api.config.getStockConfigs();
    let temp = {};
    configs.sort((a,b) => {return a.stock - b.stock;});
    for (let x = 0; x < configs.length; x++) {
      temp[configs[x].id] = configs[x];
    }
    setStockConfigs(temp);
    setStockCount(configs.length);
  };

  function updateSearch(event) {
    event.preventDefault();
    if (event.target.value) {
      setStockCount(Object.values(stockConfigs).reduce((acc, current) => {
        if (current.name.toLowerCase().includes(event.target.value.toLowerCase())) return acc+1;
        return acc;
      }, 0));
    } else setStockCount(Object.keys(stockConfigs).length);
    setSearch(event.target.value);
  }

  function generatePaginationItems(len) {
    const times = Math.ceil(len / ITEMS_PER_PAGE);
    let ret = [];
    for (let x = 0; x < times; x++) {
      ret.push(<Pagination.Item active={x === page} key={x} onClick={()=>{setPage(x);}} >{x+1}</Pagination.Item>);
    }
    return ret;
  }

  function getPaginatedTableEntries() {
    let ret = [];
    
    const searchedStock = search ? Object.values(stockConfigs).filter((stock) => {
      if (stock.name.toLowerCase().includes(search.toLowerCase())) return stock;
      return undefined;
    }) : Object.values(stockConfigs);

    
    for (let x = page*ITEMS_PER_PAGE; x < (page+1)*ITEMS_PER_PAGE; x++) {
      let stock = searchedStock[x];
      if (!stock) continue;
      ret.push(
        <tr key={stock.id}>
          <td>{stock.name}</td>
          <td>{HR_TYPE[stock.configType]}</td>
          <td><button className="btn btn-primary" onClick={async function (event) {
            // check if the user has a valid card, if not require they input one
            try {
              let user = api.user.getCachedUser();
              if (user.status === "NOCARD" && (user.freetime <= 0 || user.configs.length > 0)) {
                await openCardModal();
              }
            } catch (rej) {
              console.log(rej);
              return Promise.resolve();
            }
            // start clone process
            return Swal.fire({
              title: 'Please name your new configuration',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Finish',
              reverseButtons: true
            }).then(result => {
              if (result.value) {
                setProcessing(true);
                return api.config.cloneConfig(stock.id,result.value).then(resp => {
                  if (resp) {
                    api.user.getUser(api.user.getCachedUser().id).then((user) => props.onNewConfig(user.configs));
                    Swal.fire({title: 'Game configuration created!', icon: 'success'});
                    setProcessing(false);
                    props.onClose();
                  } else {
                    Swal.fire({title: 'Error creating game configuration, please try again...', icon: 'error'});
                  }
                });
              } else {
                return null;
              }
              // 
            });
          }}>Add...</button></td>
        </tr>
      );
    }
    return ret;
  }
  
  return (
    <>
      <Modal id="cloneModal" title={isProcessing ? "Creating new game configuration, please wait..." : "Add new game configuration"} size="xl" onOpen={onOpen} onClose={props.onClose} visible={props.visible} enforceFocus={false}>
        { isProcessing ? <Loader /> :
          <>
            <input type="text" placeholder="Search..." className="mb-3" value={search} onChange={updateSearch} />
            <table className="table" style={{width: '100%'}}>
              <thead>
                <tr>
                  <td style={{width: '60%'}}>Name</td>
                  <td style={{width: '25%'}}>Type</td>
                  <td style={{width: '15%'}}>Add</td>
                </tr>
              </thead>
              <tbody>
                { getPaginatedTableEntries() }
              </tbody>
            </table>
            <Pagination>
              <Pagination.First onClick={()=>{setPage(0);}} disabled={stockCount <= ITEMS_PER_PAGE} />
              <Pagination.Prev onClick={()=>{setPage(page > 0 ? page-1 : page);}} disabled={stockCount <= ITEMS_PER_PAGE} />
              { generatePaginationItems(stockCount) }
              <Pagination.Next onClick={()=>{setPage(page < (stockCount/ITEMS_PER_PAGE)-1 ? page+1 : page);}} disabled={stockCount <= ITEMS_PER_PAGE} />
              <Pagination.Last onClick={()=>{setPage(Math.ceil(stockCount/ITEMS_PER_PAGE)-1);}} disabled={stockCount <= ITEMS_PER_PAGE} />
            </Pagination>
          </>
        }
      </Modal>
      <CardModal {...cardModalProps} />
    </>
  );
}
