import React from 'react';
import {useSize} from 'api/hooks';
import { zeroPad } from 'api/util';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  AreaSeries,
  VerticalRectSeries,
} from 'react-vis';

function calculateTickTotal(width, emSize, divisor) {
  let ret = Math.floor(width / emSize / (divisor || 5));
  return ret;
}

export default function Graph(props) {
  const sizes = useSize(props.parentId);
  return (
    <FlexibleWidthXYPlot height={props.height || 300} margin={{left: 75}}>
      {
        props.yAxis ?
        <YAxis
          style={{text: {fill: '#ddd'}}}
          title={props.yTitle}
          tickFormat={props.yFormat}
          tickTotal={props.parentId ? calculateTickTotal(sizes.y, sizes.em, 8) : undefined}
        />
        : undefined
      }
      <XAxis
        tickFormat={props.xFormat}
        title={props.xTitle}
        style={{text: {fill: '#ddd'}, line: {stroke: '#ddd'}}}
        tickTotal={props.parentId ? calculateTickTotal(sizes.x, sizes.em) : undefined}
      />
      <AreaSeries
        curve="curveMonotoneX"
        data={props.data}
        color="#16ab9e"
      />
    </FlexibleWidthXYPlot>
  );
}

export function BarGraph(props) {
  const sizes = useSize(props.parentId);
  return (
    <FlexibleWidthXYPlot height={props.height || 300} margin={{left: 75}}>
      {
        props.yAxis ?
        <YAxis
          style={{text: {fill: '#ddd'}}}
          title={props.yTitle}
          tickFormat={props.yFormat}
          tickTotal={props.parentId ? calculateTickTotal(sizes.y, sizes.em, 8) : undefined}
        />
        : undefined
      }
      <XAxis
        tickFormat={props.xFormat}
        title={props.xTitle}
        style={{text: {fill: '#ddd'}, line: {stroke: '#ddd'}}}
        tickTotal={props.parentId ? calculateTickTotal(sizes.x, sizes.em) : undefined}
      />
      <VerticalRectSeries
        data={props.data}
        color="#16ab9e"
      />
    </FlexibleWidthXYPlot>
  );
}

export function dateFormat(value, index, scale, tickTotal) {
  const date = new Date(value);
  return `${zeroPad(date.getMonth()+1,2)}/${zeroPad(date.getDate()+1,2)}`;
}

export function dollarFormat(value, index, scale, tickTotal) {
  return `$${value.toFixed(2)}`;
}
