import React, {useState} from 'react';
import {Mdi} from 'parts/Parts.jsx';
import {Link} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Dropdown from 'react-bootstrap/Dropdown';

function UserDropdown(props) {
  const { user, isAuthenticated, logout } = useAuth0();
  const [shadow, setShadow] = useState(false);
  const hover = () => {setShadow(true);};
  const leave = () => {setShadow(false);};

  let imgSrc = "";
  
  if (isAuthenticated && user && user.picture) {
    imgSrc = user.picture;
  } else {
    imgSrc = "https://www.gravatar.com/avatar/none?d=mp";
  }

  // prevent rendering the dropdown if the user is stuck in a single-page mode
  if (props.disabled) {
    return <img src={imgSrc} onMouseOver={hover} onMouseLeave={leave}
                alt="user icon" className={"rounded-circle" + (shadow ? " shadow" : "")}
                style={{'borderRadius': '50px', width: '50px', height: '50px'}} />;
  }
  
  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-user" onMouseOver={hover} onMouseLeave={leave}>
        <img src={imgSrc}
           alt="user icon" className={"rounded-circle" + (shadow ? " shadow" : "")} style={{'borderRadius': '50px', width: '50px', height: '50px'}} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/user">User settings</Dropdown.Item>
        <Dropdown.Item href="/logout" onClick={event => {event.preventDefault(); logout({ returnTo: "https://ferrofluidgames.com/" });}}>Log out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Header(props) {
  //  const [isOpen, setOpen] = useState(false);
  //  const toggle = () => {setOpen(!isOpen);};

  // TODO: add a user dropdown menu
  return (
    <nav className="d-flex navbar navbar-dark">
      {props.singlePage ? undefined :
       <div>
         <button className="btn btn-dark d-md-none" id="hamburgerButton" onClick={(e) => {
           e.preventDefault();
           window.$("#wrapper").toggleClass("toggled");
         }}><Mdi color="#16aa9d" size={2}>hamburger</Mdi></button>
       </div>
      }

      {props.singlePage ?
       <div className="navbar-brand">
         <img style={{height: '50px'}} src="/img/FerroFluid_full_logo.png" alt="FerroFluid logo"/>
       </div>
       : undefined}
      <div className="float-right">
        <UserDropdown disabled={props.singlePage} />
      </div>
    </nav>
  );

  /*<Navbar color="light" light={true} className="d-flex flex-row-reverse">
    <NavbarToggler tag={UserIcon} src="/img/ff_icon_512.png" onClick={toggle} />
    <Collapse isOpen={isOpen} navbar>
    <Nav className="mr-auto" navbar>
    <NavItem>
    <NavLink tag={Link} to="/">Home</NavLink>
    </NavItem>
    </Nav>
    </Collapse>
    </Navbar>*/
}

export default Header;
