import React, { useState } from 'react';
import Sidebar from './Sidebar.jsx';
import Header from './Header.jsx';
import Routes from './Routes.jsx';
import api from 'api/API.js';
import { BrowserRouter } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from 'parts/Parts.jsx';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// user status pages
import NewUser from 'views/user/NewUser.jsx';
import Delinquent from 'views/user/Delinquent.jsx';
import CardModal from 'views/user/CardModal.jsx';
import InactiveUser from 'views/user/InactiveUser.jsx';
import { Elements } from '@stripe/react-stripe-js';

function closeSidebar(e) {
  const sidebar = window.$("#wrapper");
  if (sidebar.hasClass("toggled") && window.$("#hamburgerButton").is(":visible")) {
    sidebar.removeClass("toggled");
    console.log("closing sidebar...");
    e.preventDefault();
  }
}

export default function App() {
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [apiStatus, setApiStatus] = useState({loaded: api.loaded, userStatus: '', user: {}});

  function updateStatus(newStatus) {
    setApiStatus({loaded: api.loaded, userStatus: newStatus, user: api.user.getCachedUser()});
  }

  if (isLoading) {
    return <Loader/>;
  }
  
  if (!isAuthenticated) {
    loginWithRedirect();
    return <Loader/>;
  }

  if (isAuthenticated && !apiStatus.loaded) {
    getAccessTokenSilently().then(token => {
      api.keys = {'id_token': token};
    });
    api.waitUntilLoaded().then(() => {
      console.log(api.user.getCachedUser().status);
      setApiStatus({loaded: true, userStatus: api.user.getCachedUser().status, user: api.user.getCachedUser()});
    });
    return <Loader/>;
  }

  switch(apiStatus.userStatus) {
  case "DELINQUENT":
    return (
      <div id="page-content-wrapper">
        <Header singlePage />
        <ToastContainer position="top-right" closeOnClick pauseOnHover />
        <div className="container-fluid">
          <Elements stripe={api.stripePromise}>
            <Delinquent callback={(user) => {updateStatus(user.status);}} />
          </Elements>
        </div>
      </div>
    );
  case "NEW":
    return (
      <div id="page-content-wrapper">
        <Header singlePage />
        <ToastContainer position="top-right" closeOnClick pauseOnHover />
        <div className="container-fluid">
          <Elements stripe={api.stripePromise}>
            <NewUser callback={(user) => {updateStatus(user.status);}} />
          </Elements>
        </div>
      </div>
    );
  case "INACTIVE":
    return (
      <div id="page-content-wrapper">
        <Header singlePage />
        <div className="container-fluid">
          <InactiveUser />
        </div>
      </div>
    );
  default:
    console.log("DEFAULT");
    return (
      <Elements stripe={api.stripePromise}>
        <BrowserRouter>
          <Sidebar />
          <div id="page-content-wrapper" style={{zIndex: 0}}>
            <Header />
            <ToastContainer position="top-right" closeOnClick pauseOnHover />
            <div className="container-fluid p-0 m-0" onClick={closeSidebar}>
              <Routes />
            </div>
          </div>
        </BrowserRouter>
        { apiStatus.user.settings && apiStatus.user.settings.validCard ? undefined : <CardModal/> }
      </Elements>
    );
  }
}
