import React from 'react'; // , {useState, useEffect}
import { Panel } from 'parts/Parts.jsx';
import CardInfo from './CardInfo.jsx';
import Swal from 'sweetalert2';
import api from 'api/API.js';
import {sleep} from 'api/util.js';

var loopCounter = 0;

export default function Delinquent(props) {
  async function wait() {
    Swal.fire({title: 'Updating payment info...', text: 'Please wait while we process your new payment information. This could take a couple of minutes.\nWe will automatically return you once the payment succeeds.', icon: 'success'});
    if (await loop()) {
      Swal.fire({title: 'Payment Failed', text: 'Looks like something went wrong with your payment.\nPlease check your email for more information.', icon: 'error'});
    } else {
      props.callback(api.user.getCachedUser());
    }
  }

  // loop 15 times or the user comes up as not delinquent, whichever happens first
  function loop(user) {
    if (user && user.status !== "DELINQUENT") {
      return true;
    } else {
      if (loopCounter > 15) {
        return false;
      }
      loopCounter++;
      return sleep(15000).then(() => {
        console.log("going around again!");
        return api.user.getUser().then(loop);
      });
    }
  }
  
  return (
    <div className="row justify-content-around">
       <Panel title="Pay your freaking bill!">
         <React.Fragment>
           <hr />
           <CardInfo callback={wait} />
         </React.Fragment>
       </Panel>
    </div>
  );
}
