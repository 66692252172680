import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";

export default function Meter(props) {
  const opts = {
    value: props.value || 0,
    minValue: props.minValue || 0,
    maxValue: props.maxValue || 100,
    width: props.width,
    height: props.height,
    segments: 10,
    maxSegmentLabels: 0,
    fluidWidth: props.width ? undefined : true,
    startColor: props.startColor || '#FF0000',
    endColor: props.endColor || '#16AB9E',
    currentValueText: props.valueFormat || '${value}',
  };
  return <ReactSpeedometer {...opts} />;
}
