import api from './API.js';
import { useState, useEffect } from 'react';

export function useUser() {
  const [user, setUser] = useState(api.user.getCachedUser());
  
  function updateUser() {
    api.user.getUser().then(ret => {ret.update = updateUser; setUser(ret);});
  }
  
  useEffect(() => {
    updateUser();
  }, []);
  
  user.update = updateUser;
  return user;
}

class User {
  constructor(idToken) {
    // grab the currently signed in user and cache it for later use
    window.fetch(api.getApiDomain() + '/user', {
      method: 'GET',
      headers: {
	'Accept': 'application/json',
	'Authorization': 'Bearer ' + idToken
      },
      cache: "no-cache"
    }).then(resp => {
      return resp.json();
    }).then(user => {this.cachedUser = user.data;});
  }
  
  getUser(id) {
    return window.fetch(api.getApiDomain() + '/user' + (id ? "/" + id : ""), api.get()).then(resp => {
      return resp.json();
    }).then(user => {
      if (this.cachedUser && user.data.id === this.cachedUser.id) { // if this is the same user as the currently signed in user, cache the response
        console.log('caching user');
        this.cachedUser = user.data;
      }
      return user.data;
    });
  }

  async updateUser(userObj) {
    let resp = await window.fetch(api.getApiDomain() + '/user', api.put(userObj));
    let dto = await resp.json();
    let user = dto.data;
    
    if (this.cachedUser && user.id === this.cachedUser.id) { // if this is the same user as the currently signed in user, cache the response
      console.log('caching user');
      this.cachedUser = user;
    }
    return user;
  }

  async subscribeUser(userId, paymentMethodId) {
    let resp = await window.fetch(api.getApiDomain() + '/user/subscribe/' + userId, api.post({
      paymentMethodId,
    }));
    let dto = await resp.json();

    return dto;
  }

  async addPromo(promo) {
    let resp = await window.fetch(api.getApiDomain() + '/user/promo/' + promo, api.post());
    let dto = await resp.json();

    console.log(dto);

    return dto;
  }

  getCachedUser() {
    return this.cachedUser;
  }

  hasPermission(perm) {
    return this.cachedUser.permissions.includes(perm);
  }

  async getUsage() {
    if (this.cachedUsage) {
      console.log("returning cached usage");
      return this.cachedUsage;
    }
    const result = await window.fetch(api.getApiDomain() + '/user/usage', api.get()).then(resp => {return resp.json();});
    this.cachedUsage = result.data;
    return result.data;
  }
}

export default User;
