import api from './API.js';

export default class Config {
  getConfig(idz) {
    if (Array.isArray(idz)) {
      if (idz.length === 0) {
        return Promise.resolve([]);
      }
      return window.fetch(api.getApiDomain() + '/config/' + idz.join(','), api.get())
        .then(resp => {return resp.json();}).then(dto => {
          if (dto.success) {
            return dto.data;
          } else {
            console.log(dto);
            return null;
          }
        });
    } else {
      return window.fetch(api.getApiDomain() + '/config/' + idz, api.get())
        .then(resp => {return resp.json();}).then(dto => {
          if (dto.success) {
            return dto.data;
          } else {
            console.log(dto);
            return null;
          }
        });
    }
  }

  getStockConfigs() {
    return window.fetch(api.getApiDomain() + '/config/stock', api.get())
      .then(resp => {return resp.json();}).then(dto => {
        return dto.data;
      });
  }

  deleteConfig(id) {
    return window.fetch(api.getApiDomain() + '/config/' + id, api.delete())
      .then(resp => {return resp.json();}).then(dto => {
        console.log(dto);
        console.log(dto.success);
        if (dto.success) {
          return dto;
        } else {
          return null;
        }
      });
  }

  cloneConfig(id, newName) {
    console.log(id);
    return window.fetch(api.getApiDomain() + '/config/' + id, api.post({name: newName}))
      .then(resp => {return resp.json();}).then(dto => {
        console.log(dto);
        console.log(dto.success);
        if (dto.success) {
          return dto.data;
        } else {
          return null;
        }
      });
  }

  updateConfig(configObj) {
    return window.fetch(api.getApiDomain() + '/config', api.put(configObj))
      .then(resp => {return resp.json();}).then(dto => {
        console.log(dto);
        console.log(dto.success);
        if (dto.success) {
          return dto.data;
        } else {
          return null;
        }
      });
  }
}
