import React, {useEffect, useState} from 'react';
import {Panel, Loader, Mdi, ReactiveTable, LoadingButton} from 'parts/Parts.jsx';
import Dropdown from 'react-bootstrap/Dropdown';
import api from 'api/API.js';
import {constants} from 'api/util';
import Swal from 'sweetalert2';

import CloneModal from './CloneModal.jsx';
import CardModal, { useCardModal } from 'views/user/CardModal.jsx';

function Configs(props) {
  const [configs, setConfigs] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [openCardModal, cardModalProps] = useCardModal();

  async function loadConfigs(configs) {
    let ret = await api.config.getConfig(configs || api.user.getCachedUser().configs);
    let temp = {};
    //      console.log(ret);
    if (Array.isArray(ret)) {
      for (let x = 0; x < ret.length; x++) {
        temp[ret[x].id] = ret[x];
      }
    } else if (ret) {
      temp[ret.id] = ret;
    }
    setConfigs(temp);
    setLoaded(true);
  }

  useEffect(() => {
    loadConfigs();
  }, []);

  async function launchInstance(id, instanceType) {
    try {
      const user = api.user.getCachedUser();
      console.log(instanceType);
      if (user.status === "NOCARD" && user.freetime <= 0) {
        await openCardModal();
      }
    } catch (rej) {
      return;
    }
    
    if (instanceType) {
      await api.config.updateConfig({id: id, ec2size: instanceType});
      loadConfigs();
    }
    let r = await api.instance.startInstance(id);
    console.log(r);
    Swal.fire('Server is now starting...', 'You can either open the server console now, or wait for a message', 'success');
    loadConfigs();
  }

  async function terminateInstance(id) {
    try {
      let servers = await api.instance.getInstance();

      console.log(servers);
      
      if (servers.length === 0) {
        Swal.fire({title: 'Whoops!', text: 'Looks like that server is still starting.\nPlease wait 30 seconds and try again!', icon: 'error'});
        return;
      }

      let server = servers.reduce((ret, current) => {
        if (ret.config === id) return current; else return ret;
      });

      console.log(server);

      if (!server || server.config !== id || !server.dns) {
        Swal.fire({title: 'Whoops!', text: 'Looks like that server is still starting.\nPlease wait 30 seconds and try again!', icon: 'error'});
        return;
      }

      await api.instance.getLog(server.dns);
      await api.instance.terminate(server.dns);
      Swal.fire({title: 'Server is now terminating', text: 'Please wait.', icon: 'success'});
      loadConfigs();
    } catch (err) {
      Swal.fire({title: 'Whoops!', text: 'Looks like that server is still starting.\nPlease wait 30 seconds and try again!', icon: 'error'});
      return;
    }
  }

  return (
    <div className="row justify-content-around m-0">
      <Panel title="Your Game Configurations" className="mx-auto" submitLabel="Add new game configuration..." submit={() => {setModalVisible(true);}}>
        <ReactiveTable split="lg">
          <thead>
            <tr>
              <td style={{width: '20%'}}>Name</td>
              <td style={{width: '25%'}}>Game</td>
              <td style={{width: '7%'}}>Size</td>
              <td style={{width: '10%'}}>Type</td>
              <td style={{width: '13%'}}>Last Modified</td>
              <td style={{width: '20%'}}>Actions</td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(configs).map(id => (
              <tr key={id}>
                <td>{configs[id].name}</td>
                <td>{configs[id].origin}</td>
                <td>{Math.round(configs[id].size / 1000000)} MB</td>
                <td>{constants.typeNames[configs[id].ec2size]}</td>
                <td>{new Date(configs[id].lastModified * 1000).toLocaleTimeString()} {new Date(configs[id].lastModified * 1000).toLocaleDateString()}</td>
                <td>
                  <div className="row">
                    
                    
                    <LoadingButton className="btn-primary col-12 col-lg-8" onClick={event => {return launchInstance(id);}} disabled={configs[id].alock}>{configs[id].alock ? "Already running" : "Launch instance..."}</LoadingButton>
                    
                    <Dropdown className="col-12 col-lg-2" style={{padding: 0, margin: 0}}>
                      <Dropdown.Toggle variant="secondary" id="dotdotdot" disabled={configs[id].alock} style={{width: '100%'}}>
                        ...
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={event => {launchInstance(id, "light");}} disabled={configs[id].alock}>Launch at Light Speed</Dropdown.Item>
                        <Dropdown.Item onClick={event => {launchInstance(id, "ridiculous");}} disabled={configs[id].alock}>Launch at Ridiculous Speed</Dropdown.Item>
                        <Dropdown.Item onClick={event => {launchInstance(id, "ludicrous");}} disabled={configs[id].alock}>Launch at Ludicrous Speed</Dropdown.Item>
                        <Dropdown.Divider />
                      </Dropdown.Menu>
                    </Dropdown>

                    {configs[id].alock ? undefined :
                     <LoadingButton className="btn-danger col-12 col-lg-2" disabled={configs[id].alock} onClick={event => {
                       return Swal.fire({
                         title: 'Are you sure you want to delete this?',
                         text: 'There is no way to get this save-data back!\nAre you sure you want to delete this?',
                         icon: 'warning',
                         showCancelButton: true,
                         reverseButtons: true
                       }).then(result => {
                         if (result.value) {
                           return api.config.deleteConfig(id).then(result => {
                             if (result) {
                               loadConfigs();
                               Swal.fire({title: 'Game configuration deleted.', icon: 'success'});
                               console.log(result);
                             } else {
                               Swal.fire({title: 'Error deleting game configuration!', icon: 'error'});
                             }
                           });
                         }
                         return null;
                       });
                     }} ><Mdi>trash</Mdi></LoadingButton>}

                    {configs[id].alockZzz ?
                     <LoadingButton className="btn-danger col-12 col-lg-2" onClick={event => {return terminateInstance(id);}}><Mdi>stop</Mdi></LoadingButton>
                     : undefined}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </ReactiveTable>
        {!loaded ? <Loader /> : null}
      </Panel>
      <CloneModal onNewConfig={loadConfigs} visible={isModalVisible} onClose={() => {setModalVisible(false);}} />
      <CardModal {...cardModalProps} />
    </div>
  );
}

export default Configs;
