import React from 'react';
import { Panel } from 'parts/Parts.jsx';
import UserForm from './UserForm.jsx';
// import api from 'api/API.js';

export default function NewUser(props) {
  return (
    <div className="row justify-content-around m-0 fill-width">
      <Panel title="Edit User">
        <UserForm />
      </Panel>
    </div>
  );

}
