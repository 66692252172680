import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import api from 'api/API.js';
import { useUser } from 'api/User';
import { constants } from 'api/util';
import {Loader, Panel} from 'parts/Parts'; // , Meter
import Graph, {BarGraph, dateFormat, dollarFormat} from 'parts/Graph';

function getGraphData(usage) {
  if (!usage || !usage.instances || usage.instances.length < 1) {
    return [];
  }
  let lineData = [];
  let barData = [];
  let total = 0;

  lineData.push({
    x: new Date(usage.stripe.start*1000),
    y: 0.0
  });

  barData.push({
    x: new Date(usage.stripe.start*1000),
    y: 0
  });

  for (let i = 0; i < usage.instances.length; i++) {
    let instance = usage.instances[i];
    
    if (instance.uptime) {
      total = (instance.uptime * constants.typePrices[instance.size]) + total;
      
      lineData.push({
        x: new Date(instance.instanceStop*1000),
        y: total
      });
      barData.push({
        x: new Date(instance.instanceStart*1000),
        x0: new Date(instance.instanceStop*1000),
        y: instance.uptime
      });
    }
  }

  lineData.push({
    x: new Date(),
    y: total
  });
  
  barData.push({
    x: new Date(),
    y: 0
  });
  
  return [lineData, barData];
}

function Root() {
  const user = useUser();
  const [usage, setUsage] = useState({waiting: true});

  useEffect(() => {
    api.user.getUsage().then(ret => {
      setUsage(ret);
    });
  }, []);
  
  if (Object.keys(user).length === 0) {
    return (
      <div className="d-flex justify-content-center p-5">
        <Loader/>
      </div>
    );
  } else {
    const [lineData, barData] = getGraphData(usage);
    return (
      <Row className="justify-content-around m-0">
        <Panel className="justify-content-center">
          <Container>
            <Row className="mx-0 mx-lg-3 justify-content-around">
              <Col xs={12} lg={11}>
                <div className="mt-1 mt-lg-0">
                  Hey {user.firstName}! {!usage.waiting && usage.instances.length > 1 ? "Welcome back!" : ""}
                </div>
                <hr className="white" />
              </Col>
            </Row>
            {usage.waiting ? <br /> :
             <Row className="mx-0 mx-lg-3 justify-content-around">
               {(usage.instances.length < 1) ? <p>Click on the "Games" button on the left to get started.</p> :
               <Col xs={12} lg={11} id="graphContainer">
                 
                 <div className="mt-1 mt-lg-0">Your estimated bill is ${(usage.stripe.total/100).toFixed(2)} due on {(new Date(usage.stripe.end*1000)).toLocaleDateString()}</div>
                 <hr className="white" />
                 <div>
                   <Graph
                     data={lineData}
                     yAxis yFormat={dollarFormat}
                     xFormat={dateFormat} parentId="graphContainer" />
                 </div>
                 <hr className="white" />
                 <div className="mb-3">
                   Usage:
                 </div>
                 <div>
                   <BarGraph
                     data={barData}
                     yAxis
                     xFormat={dateFormat}
                     parentId="graphContainer"
                   />
                 </div>
                </Col>
               }
             </Row>
            }
          </Container>
        </Panel>
      </Row>
    );
  }
}

export default Root;
