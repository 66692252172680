import React from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import api from 'api/API.js';
import './CardElement.css';
import Swal from 'sweetalert2';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CardInfo(props) {
  const stripe = useStripe();
  const elements = useElements();

  async function submit(event) {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log(error);
      Swal.fire({title: 'Whoops!', text: 'There was an error submitting that card.\nPlease try again!', icon: 'error'});
      return;
    }

    console.log(paymentMethod);
    const response = await api.user.subscribeUser(api.user.getCachedUser().id, paymentMethod.id);
    console.log(response);
    
    props.callback();
  }

  return (
    <div className="col-12 col-lg-10 m-1">
      <div className="col-12 col-lg-6 pl-1">
        <p>As we bill for what you use, we require that you enter your payment information before you can use our service.</p>
        <p>Don't worry, we won't charge anything until you've started a server.</p>
        <p>Please enter your card payment information:</p>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <p className="m-0"><small>Payment processing done by <a href="https://stripe.com/">Stripe</a>.</small></p>
        <button className="btn btn-primary my-3" onClick={submit}>Save card</button> <br />
      </div>
    </div>
  );
}
