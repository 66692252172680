import React, {useMemo} from 'react';

export default function ReactiveTable(props) {
  let cards = useMemo(() => {
    let ret = null;
    let headers = [];
    for (let x = 0; x < props.children.length; x++) {
      let tbody = props.children[x];
      if (tbody.type === "tbody") {
        ret = tbody.props.children.map(tr => {
          if (!tr || tr.type !== "tr") {
            return undefined;
          } else {
            let col = -1;
            return (
              <React.Fragment key={tr.key}>
                <div className="card">
	          <div className="card-body">
	            {tr.props.children.map(td => {
                      if (td.type !== "td") {
                        return undefined;
                      } else {
                        col = col + 1;
                        return (
                          <React.Fragment key={"forcedKey-" + col}>
                            <div className="d-flex">
                              <div className="mr-auto">{headers[col]}</div>
                              <div style={{'maxWidth': '70%', 'textAlign': 'right'}}>{td.props.children}</div>
                            </div>
                            <hr />
                          </React.Fragment>
                        );
                      }
                    })}
	          </div>
                </div>
                <br />
              </React.Fragment>
            );
          }
        });
      } else if (tbody.type === "thead") {
        let tr = tbody.props.children;
        for (let y = 0; y < tr.props.children.length; y++) {
          let td = tr.props.children[y];
          headers.push(td.props.children);
        }
      }
    }
    return ret;
  }, [props.children]);
  
  return (
    <>
      <div className={"d-none d-" + (props.split || "md") + "-table"} style={{width: '100%'}}>
        <table className="table">
          {props.children}
        </table>
      </div>
      <div className={"d-block d-" + (props.split || "md") + "-none"} style={{width: '100%'}}>
        {cards}
      </div>
    </>
  );
}
