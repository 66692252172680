import React from 'react';
import { Panel } from 'parts/Parts.jsx';
import UserForm from './UserForm.jsx';

export default function NewUser(props) {
  function completeFirstScreen(updatedUser) {
    props.callback({status: (window.location.host === "console.dev.ferrofluidgames.com" || window.location.host === "localhost:3000") ? "INACTIVE" : "ACTIVE"});
  }
  
  return (
    <div className="row justify-content-around">
      <Panel title="Welcome to FerroFluid!">
        <hr />
        <UserForm callback={completeFirstScreen} />
      </Panel>
    </div>
  );
}
