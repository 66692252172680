import React, {useEffect, useState} from 'react';
import {Panel, Loader, ReactiveTable} from 'parts/Parts.jsx';
import api from 'api/API.js';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {Link} from 'react-router-dom';

export default function Admin(props) {
  const [users, setUsers] = useState({});
  const [instances, setInstances] = useState([]);

  useEffect(() => {
    api.admin.getAllInstances().then(rInstances => {
      rInstances.sort((a, b) => {
        return b.instanceStart - a.instanceStart;
      });
      
      setInstances(rInstances);
    });
    
    api.admin.getAllUsers().then(rUsers => {
      console.log(rUsers.length);
      let temp = {};
      for (var x = 0; x < rUsers.length; x++) {
        temp[rUsers[x].id] = rUsers[x];
      }
      setUsers(temp);
    });
  }, []);

  if (instances.length !== 0 && Object.keys(users).length !== 0) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Panel title="Running Instances" className="d-flex justify-content-center">
              <ReactiveTable>
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>User</td>
                    <td>Started</td>
                    <td>EC2 ID</td>
                    <td>DNS</td>
                  </tr>
                </thead>
                <tbody>
                  {instances.map(i => {
                    if (i.instanceStop) {
                      return undefined;
                    } else {
                      return (
                        <tr key={i.id}>
                          <td><Link to={"/server/" + i.id}>{i.id}</Link></td>
                          <td>{users[i.user].username}</td>
                          <td>{new Date(i.instanceStart*1000).toLocaleTimeString()} {new Date(i.instanceStart*1000).toLocaleDateString()}</td>
                          <td>{i.ec2Id}</td>
                          <td>{i.dns}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </ReactiveTable>
            </Panel>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div><Button data-toggle="collapse" data-target="#collapseUsers" aria-expanded="false" aria-controls="collapseUsers">Toggle users view</Button></div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="collapse" id="collapseUsers">
              <Panel title="All Users" className="d-flex justify-content-center">
                
                
                <ReactiveTable>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Status</td>
                      <td>Email Address</td>
                      <td>Stripe ID</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(users).map(u => 
                      <tr key={u.id}>
                        <td>{u.firstName} {u.lastName}</td>
                        <td>{u.status}</td>
                        <td>{u.email}</td>
                        <td>{u.stripeId}</td>
                      </tr>
                    )}
                  </tbody>
                </ReactiveTable>
              </Panel>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <div className="row justify-content-center">
        <Loader/>
      </div>
    );
  }
}
